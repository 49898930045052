.private-wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 24px 0px 27px;
  display: flex;
  justify-content: space-between;
}
.private-wrap .item {
  font-family: PingFangSC-Light;
  font-size: 14px;
}
.private-wrap .ba {
  text-align: right;
  padding-top: 13px;
  vertical-align: middle;
  height: 20px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
}
.private-wrap .ba img {
  height: 18px;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -2px;
}
.private-wrap .link {
  color: inherit;
  text-decoration: none;
}
.private-wrap .link:hover,
.private-wrap .link:visited,
.private-wrap .link:active {
  color: inherit;
}
