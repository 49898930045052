.order-component,
.modal-component {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  position: absolute;
}
.order-component .mask,
.modal-component .modal .mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  position: fixed;
}
.noblur {
  display: flex;
  margin: 0px auto;
  width: 100%;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  z-index: 100;
}
.order-inner {
  padding: 28px 20px;
  position: fixed;
  color: #425B76;
  border-radius: 4px;
  background: #fff;
  z-index: 100;
}
.order-inner .close-icon,
.modal .close {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url(../assets/close-icon.png) 0px 0px no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}
.order-inner .dialog-header {
  border-bottom: 1px solid #DFE3EB;
  display: flex;
}
.order-inner .dialog-header .item {
  font-size: 20px;
  font-family: PingFangSC;
  font-weight: 500;
  line-height: 28px;
  padding-bottom: 15px;
  cursor: pointer;
  position: relative;
  width: 50%;
  text-align: center;
}
.order-inner .dialog-header .item.active {
  color: #f0a457;
}
.order-inner .dialog-header .item.active:after {
  background: #F0A457;
}
.order-inner .dialog-header .item:after {
  display: block;
  width: 120px;
  position: absolute;
  bottom: -1px;
  height: 2px;
  left: 32px;
  content: ' ';
  background: transparent;
}
.order-inner .field-label:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.order-inner .field-label {
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #2d3e50;
  line-height: 22px;
}
.order-inner .field-label .error-tip {
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 400;
  display: none;
  color: #f2545b;
  line-height: 22px;
  float: right;
}
.order-inner .field-item {
  margin-top: 20px;
  width: 360px;
}
.order-inner .field-item.haserror .error-tip {
  display: inline-block;
}
.order-inner .field-item.haserror .field-value {
  border-color: red;
}
.order-inner .field-value {
  margin-top: 8px;
  width: 100%;
  padding: 13px 16px;
  background: #ffffff;
  border-radius: 4px;
  line-height: 22px;
  border: 1px solid #dfe3eb;
}
.order-inner .dialog-btn {
  width: 100%;
  background: #00a2c0;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  padding: 13px 0px;
  margin-top: 30px;
}
.order-inner .dialog-btn.disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
}
.modal {
  background: #ffffff;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  height: 156px;
  box-sizing: content-box;
  transform: translate(-50%, -50%);
  padding: 41px 60px;
}
.modal .img-wrap {
  width: 100px;
  height: 100px;
  text-align: center;
  background: #00a2c0;
  margin: 0px auto;
  border-radius: 50px;
}
.modal .img-wrap .img {
  width: 60%;
  display: inline-block;
  margin-top: 25px;
}
.modal p {
  margin-top: 29px;
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC;
  font-weight: 500;
  color: #f0a457;
  line-height: 28px;
}
